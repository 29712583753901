<template>
  <div style="text-align: justify">
    <p class="mb-4">
      <stemble-latex content="$\textbf{Part A.}$" />
      The first order oxidation of benzyl alcohol with potassium permanganate is monitored using a
      spectrophotometer at a temperature,
      <stemble-latex content="$\text{T}_1,$" />
      of
      <number-value :value="T1" unit="^\circ\text{C.}" />
      Using this data, construct a first order plot and use it to determine the rate constant,
      <stemble-latex content="$\text{k}_1,$" />
      for the reaction. Note that the molar absorptivity,
      <stemble-latex content="$\varepsilon,$" />
      of the sample is
      <stemble-latex content="$2350\,\text{M}^{-1}\text{cm}^{-1}$" />
      and the path length,
      <stemble-latex content="$\,\ell=1.00\,\text{cm.}$" />
    </p>

    <v-simple-table style="width: 300px" class="pl-6 mb-6" dense>
      <tbody>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\textbf{Time (min) }$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$\textbf{Absorbance}$" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$15.0$" />
          </td>
          <td style="vertical-align: middle">
            <latex-number :number="abs15" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$30.0$" />
          </td>
          <td style="vertical-align: middle">
            <latex-number :number="abs30" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$45.0$" />
          </td>
          <td style="vertical-align: middle">
            <latex-number :number="abs45" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$60.0$" />
          </td>
          <td style="vertical-align: middle">
            <latex-number :number="abs60" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$75.0$" />
          </td>
          <td style="vertical-align: middle">
            <latex-number :number="abs75" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <p class="mb-5">
      <stemble-latex content="$\textbf{Part B.}$" />
      If the rate constant for the reaction is
      <number-value :value="k2" unit="\text{min}^{-1}" />
      when the temperature is increased to
      <number-value :value="T2" unit="^\circ\text{C,}" />
      determine the activation energy,
      <stemble-latex content="$\text{E}_{\text{a}},$" />
      of the reaction.
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';
import NumberValue from '../NumberValue';

export default {
  name: 'UPEIBuffersPreLab',
  components: {
    StembleLatex,
    LatexNumber,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    k1() {
      return this.taskState.getValueBySymbol('k1').content;
    },
    k2() {
      return this.taskState.getValueBySymbol('k2').content;
    },
    T1() {
      return this.taskState.getValueBySymbol('T1').content;
    },
    T2() {
      return this.taskState.getValueBySymbol('T2').content;
    },
    abs15() {
      return (2350 * 4.5e-4 * Math.exp(-this.k1.toFloat() * 15)).toFixed(3);
    },
    abs30() {
      return (2350 * 4.5e-4 * Math.exp(-this.k1.toFloat() * 30)).toFixed(3);
    },
    abs45() {
      return (2350 * 4.5e-4 * Math.exp(-this.k1.toFloat() * 45)).toFixed(3);
    },
    abs60() {
      return (2350 * 4.5e-4 * Math.exp(-this.k1.toFloat() * 60)).toFixed(3);
    },
    abs75() {
      return (2350 * 4.5e-4 * Math.exp(-this.k1.toFloat() * 75)).toFixed(3);
    },
  },
};
</script>
